import * as React from "react"
import { graphql, Link, PageProps } from "gatsby"
import Layout from "../../components/layout"
import { allBlog, blogLink, blogSection, date } from "./blog.module.css"
import "@fontsource/roboto-mono/200.css"

export const pageQuery = graphql`
  query {
    allMdx(sort: { fields: frontmatter___date, order: DESC }) {
      nodes {
        id
        frontmatter {
          date(formatString: "MMMM YYYY")
          title
          blurb
        }
        body
        slug
      }
    }
  }
`

interface BlogPageProps extends PageProps {
  data: {
    allMdx: {
      nodes: [
        {
          id: string
          frontmatter: {
            date: string
            title: string
            blurb?: string
          }
          body: string
          slug: string
        }
      ]
    }
  }
}

const postsToHide = ["Example"]

function convertNewlinesToHtml(textWithNewlines: string) {
  const test = textWithNewlines
    .trim()
    .split("\n")
    .map((line) => `${line}<br />`)
    .join("")
  return test
}

const BlogPage = (props: BlogPageProps) => {
  const blogPosts = props.data.allMdx.nodes.filter(
    (node) => !postsToHide.includes(node.frontmatter.title)
  )

  return (
    <Layout pageHeader="Blog" pageTitle="Blog">
      <div className={allBlog}>
        <ul>
          {blogPosts.map(({ id, slug, frontmatter }) => (
            <div className={blogSection}>
              <article key={id}>
                <Link to={`/blog/${slug}`}>
                  <h2 className={blogLink}>{frontmatter.title}</h2>
                </Link>
                <p className={date}>{frontmatter.date}</p>
                {frontmatter.blurb && (
                  <p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: convertNewlinesToHtml(frontmatter.blurb),
                      }}
                    />
                  </p>
                )}
              </article>
            </div>
          ))}
        </ul>
        {blogPosts.length === 0 && <p>TODO: write blog</p>}
      </div>
    </Layout>
  )
}

export default BlogPage
